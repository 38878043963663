<template>
  <b-row>

    <!-- Diploma-->
    <b-col md="4">
      <b-row>

        <b-col cols="12 mb-1">
          <div class="d-flex">
            <icon icon="user-graduate"
                  size="2x"/>
            <h4 class="mb-0 ml-1 my-auto">
              {{ capitalize($tc('diploma')) }}
            </h4>
          </div>
        </b-col>

        <b-col cols="12"
               class="mb-1">
          <field-file
              :singleFile.sync="employee.diploma"
              :documentType="'diploma'"
          />
        </b-col>
      </b-row>
    </b-col>

    <!-- Car insurances-->
    <b-col md="4">
      <b-row>

        <b-col cols="12 mb-1">
          <div class="d-flex">
            <icon icon="car"
                  size="2x"/>
            <h4 class="mb-0 ml-1 my-auto">
              {{ capitalize($tc('carInsurance', 2)) }}
            </h4>
          </div>
        </b-col>

        <b-col cols="12"
               class="mb-1">

          <content-document
              v-for="(document, index) in employee.carInsurances"
              :document="document"
              @delete="employee.carInsurances.splice(index,1)"
          />


          <field-file
              :singleFile.sync="newCarInsurance"
              :documentType="'carInsurance'"
              :key="componentKey"
          />
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import FieldFile from '@/components/input/File'
import ContentDocument from '@/components/card/components/DocumentContent'

export default {
  components: {
    FieldFile,
    ContentDocument
  },
  props: {
    employee: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)
    const newCarInsurance = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newCarInsurance, (val) => {
      if (val != null) {
        props.employee.carInsurances.push(JSON.parse(JSON.stringify(val)))

        newCarInsurance.value = null
        componentKey.value++
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      componentKey,
      newCarInsurance,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>